.rich-text {
  max-width: 900px;
  margin: auto;
  p {
    font-size: 16px;
    margin-bottom: 24px;
    letter-spacing: 1px;

    @include media-md {
      font-size: 18px;
    }
  }
  a {
    color: tomato;
    font-weight: 600;
    font-style: italic;

    &:hover,
    &:active {
      color: tomato;
      font-weight: 600;
      font-style: italic;
    }
  }
  .block-img {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 36px;

    img {
      max-width: 100%;
    }
  }
  .embed-youtube {
    text-align: center;
    margin: auto 32px;
  }
}
