// ------------------------------------------------------ \\
// This file contains all BASE styles.
// ------------------------------------------------------ \\

/**
 * Set up a decent box model on the root element
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  background-color: $black-dark;
  font-family: $font-avenir;
  width: auto;
  overflow-x: hidden;
  min-height: 100vh;
  padding-right: 0px !important;
  font-size: 16px;
}

@include media-md {
  // Custom scrollbar
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #0f0708;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
