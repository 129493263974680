.press-single-item {
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 1.1rem;
  }

  .btn {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }
}
