.btn {
  letter-spacing: 0.8px;
  font-size: 14px;
  padding: 10px 36px;
  transition: 0.3s;
  font-weight: 600;
  outline: none !important;
  outline-color: rgba(0, 0, 0, 0);
  outline-style: none;
  box-shadow: none;

  &:active,
  &:visited,
  &:focus,
  &:active:focus {
    outline: none !important;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: none;
    box-shadow: none;
  }

  &:hover {
    transform: scale(1.05);
    outline: none !important;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: none;
    box-shadow: none;
  }

  &--xsmall {
    font-size: 11px;
    padding: 6px 18px;
  }

  &--small {
    font-size: 12px;
    padding: 8px 20px;
  }

  &-white {
    background-color: $white-primary;
    color: $black-dark;
  }

  &-white-outline {
    border: 2px solid $white-primary;
    color: $white-primary;

    &:hover {
      color: $white-primary;
    }
  }
}

.btn:focus {
  outline: none;
}
