.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-scale {
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}

.text-non-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
