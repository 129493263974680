.navbar-mobile {
  color: $white-primary;
  z-index: 500;

  &__button {
    position: fixed;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($black-dark, 0.2);
    border-radius: 100%;
    border: none;
    outline: none;
    color: $white-primary;
    z-index: 999;

    svg {
      width: 14px;
    }

    @include media-lg {
      right: 32px;
      top: 32px;
      width: 38px;
      height: 38px;
      background-color: rgba($black-dark, 0.3);

      svg {
        width: 16px;
      }
    }

    &:checked,
    &:not(:hover),
    &:visited,
    &:active,
    &:hover {
      outline: none;
    }
  }

  &__main {
    background: $black-dark;
    position: fixed;
    z-index: 10;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 70%;
    max-width: 300px;
    transition: 0.5s;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-y: scroll;

    @include media-sm {
      width: 50%;
    }

    @include media-lg {
      max-width: 400px !important;
    }

    /* width scrollbar */
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    &--visible {
      left: 0%;
    }
  }

  &__item {
    a {
      font-size: 14px;
      display: block;
      color: $white-primary;
      text-decoration: none;
      text-transform: uppercase;
      margin-bottom: 32px;
      letter-spacing: 0.5px;
      transition: 0.3s;
      &:hover {
        color: #fff;
        text-decoration: none;
        transform: scale(1.02);
      }
    }
  }

  &__contact-button {
    max-width: 200px;
  }
}
