.footer {
  background-color: $black-dark;
  color: $white-primary;

  &__logo {
    width: 80%;
    max-width: 400px;
    margin: auto;
  }

  &__icon {
    font-size: 20px;
    color: $white-primary;
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
      color: $white-primary;
    }
  }

  &__link {
    // text-transform: uppercase;
    color: $white-primary;
    text-decoration: none;
    font-weight: 600;
    font-size: 11px;
    &::after {
      height: 1px;
      content: "";
      display: block;
      margin: 6px auto 0;
      bottom: 0;
      overflow: hidden;
      background-color: #fff;
      max-width: 100px;
      width: 30%;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: left;
      transform-origin: left;
      transition: transform 0.3s ease-in-out;
    }
    &:hover {
      text-decoration: none;
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &__name {
    font-size: 12px;
    letter-spacing: 1px;
  }
}
