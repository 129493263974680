// ------------------------------------------------------------------ \\
// Default colours                                                     \\
// ------------------------------------------------------------------ \\

//https://flatuicolors.com/palette/ca

//aqua
$aqua-dark: rgba(1, 163, 164, 1); //#01A3A4
$aqua-light: rgba(0, 210, 211, 1);
//green
$green-dark: rgba(16, 172, 132, 1);
$green-light: rgba(29, 209, 161, 1);
//blue
$blue-dark: rgba(46, 134, 222, 1);
$blue-dark-pastel: rgba(10, 189, 227, 1);
$blue-light: rgba(84, 160, 255, 1);
$blue-light-pastel: rgba(72, 219, 251, 1);
//purple
$purple-main: rgba(51, 1, 54, 1);
$purple-dark: rgba(52, 31, 151, 1);
$purple-light: rgba(95, 39, 205, 1);
//pink
$pastel-pink: #ded0d0;
//yellow
$yellow-dark: rgba(255, 159, 67, 1); //#FF9F43
$yellow-light: rgba(254, 202, 87, 1);
//red
$red-dark: rgba(219, 65, 67, 1);
$red-light: rgba(255, 107, 107, 1);
//orange
$orange-light: rgba(255, 112, 53, 1);
//gray
$gray-dark: #777;
$gray-light: rgba(200, 214, 229, 1);
$gray-bg: rgb(242, 242, 242) !important;
//black
$black-dark: #0f0708;
$black-light: rgba(87, 101, 116, 1);
//white
$white-primary: rgba(245, 245, 245, 1);

// ------------------------------------------------------------------ \\
// Colour Helpers                                                     \\
// ------------------------------------------------------------------ \\

// .primary-color {
//   color: $primary-color-1;
// }

// .secondary-color {
//   color: $secondary-color;
// }

.white-smoke {
  color: $white-primary;
}

.bg-dark {
  background-color: $black-dark !important;
}
