@font-face {
  font-family: "Avenir";
  src: url("../../assets/fonts/AvenirNextLT/AvenirNextLTW01-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/AvenirNextLT/AvenirNextLTW01-Regular.woff")
      format("woff"),
    url("../../assets/fonts/AvenirNextLT/AvenirNextLTW01-Regular.ttf")
      format("truetype"),
    url("../../assets/fonts/AvenirNextLT/AvenirNextLTW01-Regular.svg#AvenirNextLTW01-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$font-avenir: "Avenir", sans-serif;

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-white {
  color: $white-primary !important;
}

.text-gray {
  color: darken($color: $white-primary, $amount: 20%);
}

.text-dark {
  color: $black-dark !important;
}

.text-red {
  color: tomato;
  font-weight: 600;
  font-style: italic;

  &:hover,
  &:active {
    color: tomato;
    font-weight: 600;
    font-style: italic;
  }
}

.text-600 {
  font-weight: 600;
}

.text-400 {
  font-weight: 400;
}

.text-700 {
  font-weight: 700;
}

.letter-spacing-05 {
  letter-spacing: 0.5px;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-6 {
  letter-spacing: 6px;
}
