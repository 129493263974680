$shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
$shadow-lg: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$shadow-xl: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
$shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$shadow-outline: 0 0 0 3px rgba(52, 144, 220, 0.5);

.shadow {
  box-shadow: $shadow;
}
.shadow-md {
  box-shadow: $shadow-md;
}
.shadow-lg {
  box-shadow: $shadow-lg;
}
.shadow-xl {
  box-shadow: $shadow-xl;
}
.shadow-inner {
  box-shadow: $shadow-inner;
}
.shadow-outline {
  box-shadow: $shadow-outline;
}
.shadow-none {
  box-shadow: none;
}
